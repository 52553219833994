"use client";

import { BASE_URL } from "#next-seo.config";
import { NextSeo } from "next-seo";
import { useEffect, useRef, useState } from "react";
import Container from "~/components/Container";
import styles from "~/styles/portable-secret.module.scss";
import { loadModuleFromString } from "~/utilities/load_module_from_string";

// seo
export const url: string = "/secret";
export const title: string = "Portable Secret";
export const description: string = "Free and open source utility to create encrypted files using web browsers.";

// config
const outputFileName = "secret.html";

// encryption
const HASH_WASM_SALT_SIZE = 16; // bytes
const ARGON2_KEY_SIZE = 32; // bytes (for a key length of 256)
const ARGON2_PARALLELISM = 1;

// https://cheatsheetseries.owasp.org/cheatsheets/Password_Storage_Cheat_Sheet.html
// const ARGON2_ITERATIONS = 4; // key derivation (argon2id: https://soatok.blog/2022/12/29/what-we-do-in-the-etc-shadow-cryptography-with-passwords)
// const ARGON2_MEMORY_SIZE = 65536; // 64 MiB

// https://soatok.blog/2022/12/29/what-we-do-in-the-etc-shadow-cryptography-with-passwords
// const ARGON2_ITERATIONS = 4; // key derivation (argon2id: https://soatok.blog/2022/12/29/what-we-do-in-the-etc-shadow-cryptography-with-passwords)
// const ARGON2_MEMORY_SIZE = 1048576; // 1024 MiB

// Based on 1 second of KeePass
const ARGON2_ITERATIONS = 12; // key derivation (argon2id: https://soatok.blog/2022/12/29/what-we-do-in-the-etc-shadow-cryptography-with-passwords)
const ARGON2_MEMORY_SIZE = 131072; // 128 MiB

function getRandomBytes(size: number) {
  if (typeof crypto !== "undefined") {
    return crypto.getRandomValues(new Uint8Array(size));
  }
  return new Uint8Array(size).fill(0).map(() => Math.floor(Math.random() * Number.MAX_SAFE_INTEGER));
}

export async function getStaticProps() {
  const { readFile } = await import("node:fs/promises");
  const loadTextFile = async (name) => {
    const response = await readFile(`public/portable-secret/${name}`).then(String);
    if (!response) {
      throw new Error(`Failed to retrieve: ${name} response: ${response}`);
    }
    return response;
  };

  const [pageTemplate, argon2, valuesTemplate] = await Promise.all([
    loadTextFile(`portable-secret.html`),
    loadTextFile(`argon2.umd.min.js`),
    loadTextFile(`values.js`),
    loadTextFile(`values.js`)
  ]);

  return {
    props: {
      pageTemplate,
      argon2,
      valuesTemplate
    }
  };
}

export default function PortableDigitalSecret({ pageTemplate, argon2, valuesTemplate }) {
  // encryption
  const [hashWasmSaltSize, setHashWasmSaltSize] = useState(HASH_WASM_SALT_SIZE);
  const [argon2Iterations, setArgon2Iterations] = useState(ARGON2_ITERATIONS);
  const [argon2Parallelism, setArgon2Parallelism] = useState(ARGON2_PARALLELISM);
  const [argon2MemorySize, setArgon2MemorySize] = useState(ARGON2_MEMORY_SIZE);
  const [argon2OutputSize, setArgon2OutputSize] = useState(ARGON2_KEY_SIZE);

  // refs
  const hashwasm = useRef<typeof import("~/lib/argon2")>(loadModuleFromString(argon2));
  const messageRef = useRef(null);
  const imageRef = useRef(null);
  const fileRef = useRef(null);
  const passwordRef = useRef(null);
  const passwordHintRef = useRef(null);

  // state
  const [ivHexString, setIvHexString] = useState(bytesToHexString(getRandomBytes(ARGON2_KEY_SIZE)));
  const [saltHashWasmHexString, setSaltHashWasmHexString] = useState(
    bytesToHexString(getRandomBytes(hashWasmSaltSize))
  );
  const [showPassword, setShowPassword] = useState(false);
  const [cipher, setCipher] = useState("");
  const [selectedInputType, _setSelectedInputType] = useState<"message" | "image" | "file">("message");
  const [errormsg, setErrorMsg] = useState("");
  // const [plainText, setPlainText] = useState(new Uint8Array());
  // const [fileExtension, setFileExtension] = useState("");
  // const [errormsg, setErrorMsg] = useState("Select secret type: message, image, or file");

  function setSelectedInputType(value: "message" | "image" | "file") {
    _setSelectedInputType(value);
    setErrorMsg("");
  }

  async function encrypt() {
    const encoder = new TextEncoder();
    // All cryptography is delegated to the browser engine through.
    // W3C Web Cryptography API standard
    // https://www.w3.org/TR/WebCryptoAPI/
    // No cryptography was hand-rolled in the making of this tool. ;-)

    setMessage("⏳ Importing key...");

    // Whatever array of bytes is in the password field
    let password = encoder.encode(passwordRef.current.value);
    if (password.length == 0) {
      throw new Error(`Empty password`);
    }

    setMessage("⏳ Deriving key from password...");

    // Salt for password derivation with hashwasm (byte array)
    let salt = hexStringToBytes(saltHashWasmHexString);
    if (salt.length != hashWasmSaltSize) {
      throw new Error(`Unexpected hashwasm salt length: ${salt.length}`);
    }

    const result = await hashwasm.current.argon2id({
      iterations: argon2Iterations,
      parallelism: argon2Parallelism,
      memorySize: argon2MemorySize,
      hashLength: argon2OutputSize / 2,
      salt,
      password
    });

    // argon2 the password
    password = encoder.encode(result);

    // Import password into a Key suitable for use with Cryptography APIs
    let key = await window.crypto.subtle.importKey(
      "raw", // a puny array of bytes
      password,
      {
        name: "AES-GCM", // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/encrypt#aes-gcm
        length: argon2OutputSize * 8 // key size in bits
      }, // What will use this key
      false, // key is not extractable
      ["encrypt"] // What they can use it for
    );

    setMessage("⏳ Preparing inputs...");

    // IV for AES
    const iv = hexStringToBytes(ivHexString);
    if (iv.length != argon2OutputSize) {
      throw new Error(`Unexpected IV length: ${iv.length}`);
    }

    let plainText;
    let fileExtension;

    // TODO move this messy stuff out of encrypt path
    // TODO handle files with no extension (currently captures full filename as extension)
    // TODO file and image are identical except for input field
    if (!selectedInputType) {
      throw new Error(`Select input type (message, file, image)`);
    } else if (selectedInputType == "message") {
      const message = (document.getElementById("text_input") as HTMLInputElement).value;
      // Message input field, as array of bytes
      plainText = new TextEncoder().encode(message);
    } else if (selectedInputType == "image") {
      const files = (document.getElementById("image_input") as HTMLInputElement).files;
      if (files.length < 1) {
        throw new Error(`No file selected`);
      }
      const f = files[0];
      const fileContent = await f.arrayBuffer();
      plainText = new Uint8Array(fileContent);
      fileExtension = f.name.split(".").pop();
    } else if (selectedInputType == "file") {
      const files = (document.getElementById("file_input") as HTMLInputElement).files;
      if (files.length < 1) {
        throw new Error(`No file selected`);
      }
      const f = files[0];
      const fileContent = await f.arrayBuffer();
      plainText = new Uint8Array(fileContent);
      fileExtension = f.name.split(".").pop();
    } else {
      throw new Error(`Unhandled input type: '${selectedInputType}'`);
    }

    if (plainText.length <= 0) {
      throw new Error(`Plaintext is empty`);
    }

    // Pad plaintext to block size, as describe in:
    // https://en.wikipedia.org/wiki/Padding_(cryptography)#PKCS#5_and_PKCS#7
    const plaintText = (function (input) {
      const output = [];
      const padAmount = ARGON2_KEY_SIZE - (input.length % ARGON2_KEY_SIZE);
      for (var i = 0; i < input.length; i++) {
        output.push(input[i]);
      }
      for (var i = 0; i < padAmount; i++) {
        output.push(padAmount);
      }
      return Uint8Array.from(output);
    })(plainText);

    setMessage("⏳ Encrypting...");

    // Encrypt with AES in 'Galois/Counter Mode' (integrity + confidentiality)
    // https://en.wikipedia.org/wiki/Galois/Counter_Mode
    let cipherBuffer = await window.crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv: iv
      },
      key,
      plaintText
    );
    let cipherHexString = bytesToHexString(new Uint8Array(cipherBuffer));

    return {
      iv: ivHexString,
      cipher: cipherHexString,
      extension: fileExtension
    };
  }

  async function createSecret() {
    setMessage("⏳ Creating Secret...");

    try {
      setMessage("⏳ Begin encryption...");

      // Return salt IV cipher as hex strings
      let encryption = await encrypt();
      setCipher(encryption.cipher); //0x string

      setMessage("⏳ Retrieving templates...");

      setMessage("⏳ Generating download...");

      const passwordHint = String(passwordHintRef.current.value || "").trim();

      let values = valuesTemplate.toString();
      values = values.replaceAll("'{.SECRET_TYPE}'", selectedInputType);
      values = values.replaceAll("'{.IV_HEX}'", String(encryption.iv));
      values = values.replaceAll("'{.CIPHER_HEX}'", String(encryption.cipher));
      values = values.replaceAll("'{.SECRET_EXTENSION}'", String(encryption.extension));

      values = values.replaceAll("'{.HASHWASM_SALT_SIZE}'", String(hashWasmSaltSize));
      values = values.replaceAll("'{.HASHWASM_SALT_HEX}'", String(saltHashWasmHexString));

      values = values.replaceAll("'{.ARGON2_ITERATIONS}'", String(argon2Iterations));
      values = values.replaceAll("'{.ARGON2_PARALLELISM}'", String(argon2Parallelism));
      values = values.replaceAll("'{.ARGON2_MEMORY_SIZE}'", String(argon2MemorySize));
      values = values.replaceAll("'{.ARGON2_SALT_SIZE}'", String(argon2OutputSize));
      values = values.replaceAll("'{.ARGON2_SALT_HEX}'", String(argon2OutputSize));
      values = values.replaceAll("'{.ARGON2_KEY_SIZE}'", String(ARGON2_KEY_SIZE));

      let secretPage = pageTemplate.toString();
      secretPage = secretPage.replaceAll('"__{{VALUES}}__"', values);
      secretPage = secretPage.replaceAll('"__{{ARGON2}}__"', argon2);
      secretPage = secretPage.replaceAll('"__{{PASSWORD_HINT}}__"', passwordHint);

      const blob = new Blob([secretPage], { type: "text/html" });
      const dataUri = window.URL.createObjectURL(blob);

      // setMessage("✅ Secret created and saved, click link below to save secret again.");
      setMessage(
        <div>
          <span id="errormsg">
            ✅ Secret created and saved,{" "}
            <a id="target_link" href={dataUri} download={outputFileName}>
              click here to save {outputFileName} again
            </a>
            .
          </span>
        </div>
      );

      // BEGIN: https://stackoverflow.com/a/63965930
      // Create blob link to download
      const link = document.createElement("a");
      link.href = dataUri;
      link.setAttribute("download", outputFileName);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
      // END: https://stackoverflow.com/a/63965930

      // Re-generate encryption keys for every new encryption
      computeSalt();
    } catch (err) {
      console.error(err);
      setMessage("❌ " + err);
    }
  }

  // Transform hexadecimal string to Uint8Array
  function hexStringToBytes(input) {
    // TODO accepts invalid (non-hex) values, e.g. ZZZZ
    for (var bytes = [], c = 0; c < input.length; c += 2) {
      bytes.push(parseInt(input.substr(c, 2), 16));
    }
    return Uint8Array.from(bytes);
  }

  async function setMessage(newMessage) {
    setErrorMsg(newMessage);
  }

  function bytesToHexString(input) {
    for (var hex = [], i = 0; i < input.length; i++) {
      var current = input[i] < 0 ? input[i] + 256 : input[i];
      hex.push((current >>> 4).toString(16));
      hex.push((current & 0xf).toString(16));
    }
    return hex.join("");
  }

  function setInputType(selectedType) {
    setSelectedInputType(selectedType);
  }

  // Salt input field (0x string)
  async function computeSalt() {
    setSaltHashWasmHexString(bytesToHexString(getRandomBytes(hashWasmSaltSize)));
  }

  useEffect(() => {
    for (let type of ["message", "file", "image"]) {
      let element = { message: messageRef, file: fileRef, image: imageRef }[type]?.current;
      if (!element) {
        continue;
      }
      if (type === selectedInputType) {
        element.hidden = false;
      } else {
        element.hidden = true;
      }
    }
  }, [messageRef, fileRef, imageRef, selectedInputType]);

  return (
    <Container>
      <NextSeo
        title={title}
        description={description}
        canonical={url}
        openGraph={{
          url: `${BASE_URL}${url}`,
          title,
          description
        }}
      />
      <div className={`flex flex-col justify-center items-start max-w-2xl mx-auto mb-16 w-full ${styles.container}`}>
        <h1 className="flex font-bold text-3xl md:text-5xl tracking-tight mb-4 text-black dark:text-white gap-x-4">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" style={{ width: 32 }}>
            {/* <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --> */}
            <path d="M336 352c97.2 0 176-78.8 176-176S433.2 0 336 0S160 78.8 160 176c0 18.7 2.9 36.8 8.3 53.7L7 391c-4.5 4.5-7 10.6-7 17v80c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24V448h40c13.3 0 24-10.7 24-24V384h40c6.4 0 12.5-2.5 17-7l33.3-33.3c16.9 5.4 35 8.3 53.7 8.3zm40-176c-22.1 0-40-17.9-40-40s17.9-40 40-40s40 17.9 40 40s-17.9 40-40 40z" />
          </svg>
          {title}
        </h1>

        <p>
          This tool runs entirely in your browser window.
          <br />
          <strong>The secret never leaves your computer!</strong>
          <br />
          To learn more about Portable Secrets,{" "}
          <a href="https://mprimi.github.io/portable-secret" target="_blank" rel="noreferrer">
            click here
          </a>
          .
        </p>

        {/* <!-- Inputs --> */}

        {/* <div hidden>
          {/* Salt: 0x /}
          <input type="text" id="salt" size={33} value={saltHexString} required />
          <input type="button" value="🔄" onClick={computeSalt} />
          
          {/* IV: 0x /}
          <input type="text" id="iv" size={33} value={cipher} required />
          <input type="button" value="🔄" onClick={refreshIV} />
          <p>
            <small>
              Salt and IV are random input coming straight from your browser&apos;s Random Number Generator. Do not
              reuse across messages.
            </small>
          </p>
        </div>*/}

        <div className={styles.password}>
          <div>
            Password (
            <div className={styles["show-password"]}>
              <label htmlFor="show_password">Show</label>
              <input
                type="checkbox"
                name="show_password"
                onClick={() => setShowPassword(!showPassword)}
                className="mr-0.5"
              ></input>
            </div>
            ):
          </div>
          <input
            type={showPassword ? "text" : "password"}
            id="password"
            className={`${styles["outlined-input"]} ${styles["password-input"]}`}
            required
            ref={passwordRef}
          />
        </div>

        <div>
          <p>Password hint (optional):</p>
          <textarea
            rows={8}
            cols={80}
            id="password_hint"
            ref={passwordHintRef}
            className={`${styles["outlined-input"]} ${styles["password-hint"]}`}
          ></textarea>
        </div>

        {/* <!-- Radio selector for type of secret --> */}

        <form className={styles["secret-type-form"]}>
          <div>
            <input
              type="radio"
              id="text_option"
              name="input_type"
              value="message"
              onClick={() => setInputType("message")}
              defaultChecked={selectedInputType === "message"}
            />
            <label htmlFor="text_option">Message</label>
          </div>
          <div>
            <input
              type="radio"
              id="image_option"
              name="input_type"
              value="image"
              onClick={() => setInputType("image")}
              defaultChecked={selectedInputType === "image"}
            />
            <label htmlFor="image_option">Image</label>
          </div>
          <div>
            <input
              type="radio"
              id="file_option"
              name="input_type"
              value="file"
              onClick={() => setInputType("file")}
              defaultChecked={selectedInputType === "file"}
            />
            <label htmlFor="file_option">File</label>
          </div>
        </form>

        {/* <!-- Input types, only one visible at time --> */}

        <div id="text_input_div" hidden={selectedInputType !== "message"} ref={messageRef} className="w-auto">
          <h2>📝 Secret message</h2>
          <textarea
            rows={16}
            cols={80}
            id="text_input"
            name="text_content"
            className={`mx-auto ${styles["outlined-input"]} ${styles["textarea"]}`}
          />
        </div>
        <div id="image_input_div" hidden={selectedInputType !== "image"} ref={imageRef}>
          <h2>🌆 Secret image</h2>
          <input type="file" id="image_input" alt="Upload" name="image_content" accept="image/*" />
        </div>
        <div id="file_input_div" hidden={selectedInputType !== "file"} ref={fileRef}>
          <h2>📎 Secret File</h2>
          <input type="file" id="file_input" name="file_content" />
        </div>

        {/* <!-- Configure encryption --> */}

        {/* <details className={styles["details"]}>
          <summary>Configure encryption</summary>
          <div>
            <textarea rows={8} cols={80} id="cipher" value={cipher} readOnly></textarea>
          </div>
        </details> */}

        {/* <!-- Generate button, and Download link --> */}

        <div className={styles["action"]}>
          <button type="button" className={`p-3 pr-6 mt-4 mb-4`} onClick={createSecret}>
            ⚡️ Generate secret
          </button>
          {typeof errormsg === "object" ? (
            errormsg
          ) : (
            <div>
              <span id="errormsg">{errormsg}</span>
            </div>
          )}
        </div>

        {/* <!-- Cipertext --> */}

        {cipher && (
          <details className={styles["details"]}>
            <summary>Show ciphertext</summary>
            <div>
              <textarea rows={8} cols={80} id="cipher" value={cipher} readOnly></textarea>
            </div>
          </details>
        )}
      </div>
    </Container>
  );
}
